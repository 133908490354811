import { createRouter, createWebHistory } from 'vue-router'
//import HomeView from '../views/HomeView.vue'
import HomeView from '../pages/custom/Home.vue'
import AboutView from '../views/AboutView.vue'
import TodosView from '../views/TodosView.vue'
//import JobsView from '../views/Jobs'
//import CoursesView from '../views/Courses'
import LoginView from '../pages/base/Login'

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: '/login',
    name: 'login',
    sectionTitle:'Login',
    loggedOut: true,
    component: LoginView
  },
  
  { path: '/profile', name:'profile', title: 'Profiel', component: () => import('../pages/base/User/Profile') },
  { path: '/profile/password', name:'profile_password', title: 'Wachtwoord', component: () => import('../pages/base/User/Password') },

  // TOOLS
  { path: '/tools', icon:'fa-gear',sectionTitle:'Tools', name:'tools_mails', title: 'Mails', component: () => import('../pages/base/Tools/Mails') },
  { path: '/tools/log', name:'tools_lot', title: 'Log', component: () => import('../pages/base/Tools/Log') },
  { path: '/tools/export', name:'tools_export', title: 'Exporteren', component: () => import('../pages/custom/Tools/Export') },
  { path: '/tools/permissions',   name:'tools_permissions', title:"Permissions", component: () => import('../pages/base/Tools/Permissions') },
  { path: '/tools/database', name:'tools_database', title: 'Database', component: () => import('../pages/base/Tools/Database') },
  { path: '/tools/migrate', name:'tools_migrate', title: 'Migrate', component: () => import('../pages/custom/Tools/Migrate') },
  { path: '/tools/cache', name:'tools_cache', title: 'Cache', component: () => import('../pages/base/Tools/Cache') },
  { path: '/tools/test', name:'tools_test', title: 'Test', component: () => import('../pages/custom/Tools/Test') },
  { path: '/tools/schedule', name:'tools_schedule', title: 'Schedule', component: () => import('../pages/custom/Tools/Schedule') },
  

  { path: '/', sectionTitle:'Dashboard', icon:'fa-gauge', title:'Overzicht', name: 'dashboard_overview', component: () => import('../pages/custom/Dashboard/Overview')},
  
  // CUSTOMERS

  // DASHBOARD
  

  // CALENDAR
  //{ path: '/calendar', sectionTitle: 'Kalender', icon:'fa-calendar-days', name:'calendar',  title:"Overzicht", component: () => import('../pages/custom/Calendar/Overview') },


// PROJECTEN
{ path: '/projects', sectionTitle:'Projecten', icon:'fa-code',  title:'Overzicht', name: 'projects_overview', component: () => import('../pages/custom/Project/List') },
{ path: '/projects/activities', title:'Activiteiten', name: 'projects_activities', component: () => import('../pages/custom/Project/Activities') },
{ path: '/projects/tags', title:'Tags', name: 'projects_tags', component: () => import('../pages/custom/Project/Tags') },
{ path: '/projects/edit/:id', name: 'projects_edit', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Project bewerken', controller:'projects', afterSave:{all:'/projects'}} },
{ path: '/projects/details/:id', name: 'projects_details', component: () => import('../pages/base/_Entity/Details'), meta:{title:'Project details', controller:'projects'} },


// KLANTEN
{ path: '/customers', sectionTitle:'Klanten', icon:'fa-people-group',  title:'Overzicht', name: 'customers_overview', component: () => import('../pages/custom/Customer/List') },
{ path: '/customers/domains', title:'Domeinen', name: 'customers_domains', component: () => import('../pages/custom/Domain/List') },
{ path: '/customers/services', title:'Services', name: 'customers_services', component: () => import('../pages/custom/Service/Customer') },
{ path: '/customers/branches', title:'Branches', name: 'customers_branches', component: () => import('../pages/custom/Customer/Branches') },
{ path: '/customers/stripcards', title:'Strippenkaarten', name: 'customers_stripcards', component: () => import('../pages/custom/Customer/Stripcards') },
{ path: '/customers/recurring', title:'Terugkerende betalingen', name: 'customers_recurringinvoicelines', component: () => import('../pages/custom/Invoice/Recurringinvoicelines') },
{ path: '/customers/recurring/:cus_id', name: 'customers_recurringinvoicelines_customer', component: () => import('../pages/custom/Invoice/Recurringinvoicelines') },
// { path: '/customers/edit/:id', name: 'customer_edit', component: () => import('../pages/custom/Customer/Edit') },
// { path: '/customers/new', name: 'customer_add', component: () => import('../pages/custom/Customer/Edit') },
{ path: '/customers/edit/:id', name: 'customers_edit', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Klant bewerken', controller:'customers', afterSave:{all:'/customers'}} },
{ path: '/customers/details/:id', name: 'customers_details', component: () => import('../pages/custom/Customer/Details'), meta:{title:'Klant details', controller:'customers'} },
{ path: '/customers/new', name: 'customers_add', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Klant toevoegen', controller:'customers', afterSave:{all:'/customers'}} },
{ path: '/customers/:id/newcontactperson', name: 'customers_addcontactperson', component: () => import('../pages/base/_Entity/AddRelation'), meta:{title:'Contactpersoon toevoegen', controller:'customers', relationController:'contactpersons', entity:'Contactperson', sourceIdField:'cus_id', afterSave:{all:'/customers/details/:id'}} },


// FACTUREN
{ path: '/invoices', sectionTitle:'Financieel', icon:'fa-euro', title:'Overzicht', name: 'invoices_overview', component: () => import('../pages/custom/Invoice/List'), meta:{type:'open'} },
{ path: '/invoices/openrules', title:'Te factureren', name: 'invoices_openrules', component: () => import('../pages/custom/Invoice/OpenRules') },
{ path: '/invoices/overdue', title:'Vervallen', name: 'invoices_overdue', component: () => import('../pages/custom/Invoice/List'), meta:{type:'overdue'} },
{ path: '/invoices/unpaid', title:'Open', name: 'invoices_unpaid', component: () => import('../pages/custom/Invoice/List'), meta:{type:'unpaid'} },
{ path: '/invoices/paid', title:'Betaald', name: 'invoices_paid', component: () => import('../pages/custom/Invoice/List'), meta:{type:'paid'} },
{ path: '/invoices/new/:cus_id', name: 'invoices_new', component: () => import('../pages/custom/Invoice/Edit'), meta:{title:'Factuur toevoegen', controller:'invoices'} },
{ path: '/invoices/new/:cus_id/:pro_id', name: 'invoices_new_project', component: () => import('../pages/custom/Invoice/Edit'), meta:{title:'Factuur toevoegen', controller:'invoices'} },
{ path: '/invoices/edit/:id', name: 'invoices_edit', component: () => import('../pages/custom/Invoice/Edit'), meta:{title:'Factuur bewerken', controller:'invoices'} },
{ path: '/invoices/report', title:'Rapportage', name: 'invoices_report', component: () => import('../pages/custom/Invoice/Report'), meta:{title:'Rapportage', controller:'report'} },

// Knowledgebase
{ path: '/knowledgebase', sectionTitle:'Kennisbank', icon:'fa-books', title:'Onderwerpen', name: 'knowledgebase_overview', component: () => import('../pages/custom/Knowledgebase/List') },
{ path: '/knowledgebase/knownissue/:id', name: 'knownissue_details', component: () => import('../pages/custom/Knowledgebase/Details'), meta:{title:'Item bekijken', controller:'knownissues', afterSave:{all:'/knownissues'}} },
{ path: '/knowledgebase/knownissue_edit/:id', name: 'knownissue_edit', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Item bewerken', controller:'knownissues', afterSave:{all:'/knownissues'}} },

// MEDEWERKERS
{ path: '/users', sectionTitle:'Gebruikers', icon:'fa-person-chalkboard', title:'Overzicht',name: 'users_overview', component: () => import('../pages/custom/User/List') },
// { path: '/users/edit/:id', name: 'user_edit', component: () => import('../pages/base/User/Edit') },
// { path: '/users/new', name: 'user_add', component: () => import('../pages/base/User/Edit') },
{ path: '/users/details/:id', name: 'users_details', component: () => import('../pages/base/_Entity/Details'), meta:{title:'Gebruiker details', controller:'users'} },
{ path: '/users/edit/:id', name: 'users_edit', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Gebruiker bewerken', controller:'users', afterSave:{all:'/users'}} },
{ path: '/users/new',  name: 'users_add', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Gebruiker toevoegen', controller:'users', afterSave:{all:'/users'}} },

// roles
{ path: '/users/roles', sectionTitle:'Rollen', title:'Rollen', name: 'roles_overview', component: () => import('../pages/base/Role/List') },
{ path: '/roles/edit/:id', name: 'roles_edit', component: () => import('../pages/base/Role/Edit') },
{ path: '/roles/new', title:'Toevoegen', name: 'roles_add', component: () => import('../pages/base/Role/Edit') },


// LIBRARY
//{ path: '/library', sectionTitle: 'Bibliotheek', icon:'fa-photo-film', className:'hasFileTree', name:'library', title:"Overzicht", component: () => import('../pages/custom/Library/Overview') },

// APPLICATIONS
{ path: '/applications', sectionTitle: 'Applicaties', icon:'fa-laptop-code', name:'applications', title:"Overzicht", component: () => import('../pages/custom/Application/Overview') },
{ path: '/aitool/domains', name: 'applications_aitool_domains', title:'Domeinen', component: () => import('../pages/custom/Application/AITool/Domains'), meta:{title:'AI Tool domains', controller:'aitool' }},
{ path: '/aitool/models', name: 'applications_aitool_models', title:'Modellen', component: () => import('../pages/custom/Application/AITool/Models'), meta:{title:'AI Tool models', controller:'aimodels' }},
{ path: '/aitool/requests', name: 'applications_aitool_requests', title:'Requests', component: () => import('../pages/custom/Application/AITool/Requests'), meta:{title:'AI Tool requests', controller:'aitool' }},
{ path: '/aitool/requests/:id', name: 'applications_aitool_requests_id', component: () => import('../pages/custom/Application/AITool/Requests'), meta:{title:'AI Tool requests', controller:'aitool' }},
//{ path: '/aitool/credits', name: 'applications_aitool_credits', title:'Credits', component: () => import('../pages/custom/Application/AITool/Credits'), meta:{title:'AI Tool credits', controller:'aitool' }},
//{ path: '/aitool/credits/:id', name: 'applications_aitool_credits', title:'Credits', component: () => import('../pages/custom/Application/AITool/Credits'), meta:{title:'AI Tool credits', controller:'aitool' }},
// { path: '/applications/details/:id', name: 'applications_details', component: () => import('../pages/base/_Entity/Details'), meta:{title:'Applicatie details', controller:'applications'} },
// { path: '/applications/new', name: 'applications_add', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Applicatie toevoegen', controller:'applications', afterSave:{all:'/applications'}} },

// SETTINGS
{ path: '/settings', sectionTitle: 'Instellingen', icon:'fa-gears', name:'settings', title:"Variabelen", component: () => import('../pages/base/Settings/Overview') },
{ path: '/settings/mailstatus', name:'settings_mailstatus', title:"Mail status", component: () => import('../pages/base/Settings/Mailstatus') },
{ path: '/settings/defaultprices', name:'settings_defaultprices', title:"Standaard prijzen", component: () => import('../pages/custom/Invoice/Defaultprices') },
{ path: '/settings/services', name:'settings_services', title:"Services", component: () => import('../pages/custom/Service/List') },
{ path: '/settings/hosters', name:'settings_hosters', title:"Hostingpartners", component: () => import('../pages/custom/Hoster/List') },
{ path: '/settings/mailtemplates', name:'settings_mailtemplates', title:"Mail templates", component: () => import('../pages/base/Settings/Mailtemplates') },
{ path: '/settings/msgraph', name:'settings_msgraph', title:"Microsoft", component: () => import('../pages/base/Settings/MSGraph') },


 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
  document.title = process.env.VUE_APP_COMPANY_NAME;
  next();
});

export default router
